// src/pages/EditorPage.js
import { React } from 'react';

import { Editor } from '../components/Editor';

const EditorPage = () => {
  return (
    <div>
      <Editor/>
    </div>
  );
};
export { EditorPage };
