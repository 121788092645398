import React from 'react';

import { FaRegHandPointRight } from "react-icons/fa";
import {Helmet} from "react-helmet";
const About = () => {
  return (
    <div>
      <Helmet>
        <title>About - ShareCrd</title>
       
    </Helmet>
    <header className="bg-dark text-white text-center py-4">
      <h1 style={{color:'white'}}>Welcome to ShareCrd</h1>
      <p>Your Ultimate Business Card Platform</p>
    </header>

    <div className="container my-5">
      <h2 className="mb-4">Why Choose ShareCrd?</h2>
      <ul className="mb-4">
        <li><FaRegHandPointRight/>‎ User-Friendly Design Studio</li>
        <li><FaRegHandPointRight/>‎ Digital and Dynamic</li>
        <li><FaRegHandPointRight/>‎ Hosted on ShareCard, Shared Anywhere</li>
        <li><FaRegHandPointRight/>‎ QR Code Integration</li>
      </ul>

      <h2 className="mb-4">How It Works:</h2>
      <ol>
        <li><FaRegHandPointRight/>‎ Create Your ShareCard</li>
        <li><FaRegHandPointRight/>‎ Review and Edit Anytime</li>
        <li><FaRegHandPointRight/>‎ Share Seamlessly</li>
        <li><FaRegHandPointRight/>‎ Analytics and Insights</li>
      </ol>

      <h2 className="my-4">Why Pay for Paper? ShareCard is Free and Eco-Friendly!</h2>
      <p>No need to spend money on paper business cards that quickly become outdated. With ShareCard, you're not just saving money; you're also contributing to a greener planet.</p>

      <p>Join ShareCard today and revolutionize the way you share your professional identity. It's time to make your first impression unforgettable!</p>

      <a href="/editcard" className="btn btn-primary mt-4">Get started at www.sharecard.com</a>
    </div>

    <footer className="bg-dark text-white text-center py-3 fixed-bottom">
      <p>&copy; {new Date().getFullYear()} ShareCard. All rights reserved.</p>
    </footer>
  </div>
  
  
  
  
  );
}
export {About};