// src/components/Home.js
import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import { UserAuth } from '../context/AuthCOntext';
import { useDispatch } from 'react-redux';
import { createNewLinkProfile } from '../redux/actions';
import { ref, uploadBytes } from '@firebase/storage';
import { getDownloadURL } from '@firebase/storage';
import { auth, storage } from '../config'; 
import axios from 'axios';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';

import animationData from '../Animation/done.json';
const NewLinkCreate = () => {
    const {user} = UserAuth();
  
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [link, setLink] = useState('');

const userId = user?.uid;
const navigate = useNavigate();
const dispatch = useDispatch();
const handleImageChange = async (e) => {
    const file = e.target.files[0];
   
  
    // Upload the image to Firebase Storage
    const ProfileStorageRef = ref(storage,`linkPageProfile/${userId}_${Date.now()}`)
   // const profileImageRef = storageRef.child(`profileImages/${userId}_${Date.now()}`);
  
   uploadBytes(ProfileStorageRef,file).then((snapshot)=>{
    console.log('profile done')
    getDownloadURL(snapshot.ref).then( url => {
      console.log(url);
      setImage(url);

    });
    
   })
  
    
  };
  const handleProfileUpdate = () => {
    // You might want to send this data to a backend for persistence
    alert(`Profile Updated!\nTitle: ${title}\nProfile Image: ${image}`);
  };
const [exist,setExist]=useState(false);
  const CheckLink = async () => {
    try {
      const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microbiolink/${link}`);
      if(response.data.exist){
        setExist(false);
      }else{
        setExist(true);
      }
      console.log(response.data.microLinks);
    } catch (error) {
      console.error('Error fetching micro websites:', error);
    }
  };

  CheckLink();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const addCard =()=>{
    CheckLink()
    if(!user ){
        navigate('/login');
    }else{
    
    if(!exist){
      alert("Url not Available")

    }else{
        
        dispatch(createNewLinkProfile(userId, title,link,image));
        openPopup();
    }
  }
   
  }


  //////////////////popup

  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const popupStyles = {
    display: isOpen ? 'block' : 'none',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    zIndex: '1000',
  };

  const overlayStyles = {
    display: isOpen ? 'block' : 'none',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: '999',
  };





  ////\\\\\\\\\\\\\\\\\\\\\\\\\\

  return (
    <div className="container text-center mt-5">
       <Helmet>
        <title>Link In Bio Tool For Free - ShareCrd</title>
        <meta name="description" content="Share your all links easily with ShareCrd.com/linkinbio fast and easily create add and edit whenever required for free. share links on any social media platform linke instaram, faceboo,twitter etc."/>
    </Helmet>
    <div className="card bg-light p-4">
      <h3 className="card-title">ShareCrd LinkInBio Tool</h3>
      <p className="card-text">Welcome to ShareCrd! Use our Link in Bio tool for Instagram, Twitter, and many more places.</p>
    </div>

    <h2 className="mt-4">Welcome, {user?.username}!</h2>
<div>
<div style={overlayStyles} onClick={closePopup}></div>

<div style={popupStyles}>
  <span style={{ cursor: 'pointer' }} onClick={closePopup}>X</span>
  <p>Your LinkInBio Page is Live!</p>
  <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      />
  <button className="btn btn-primary"  onClick={()=>{navigate('/addlinkinbio/'+link)}}>Add Links</button>
</div>
</div>
    <div className="card bg-light mt-4 p-4">
      <h3>Create New LinkInBio Page</h3>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />

      <div className="mb-3">
        <label className="form-label">Profile Photo:</label>
        <input
          type="file"
          className="form-control"
          onChange={handleImageChange}
        />
      </div>
    </div>

    <div className="card bg-light mt-4 p-4">
      <h3>Set unique url for your new LinkInBio page. Eg.: pratik or pratik-page</h3>
      <input
        type="text"
        className="form-control mb-3"
        placeholder="Set Public URL"
        value={link}
        onChange={(e) => setLink(e.target.value.trim())}
      />
      <button className="btn btn-primary" onClick={addCard}>Create Page</button>
    </div>
  </div>
  );
};

export {NewLinkCreate};
