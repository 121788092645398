// src/pages/PreviewPage.js
import React, { useState, useEffect } from 'react';
import {FaUser, FaEnvelope, FaPhone, FaBuilding, FaMapMarker, FaLinkedin, FaTwitter, FaFacebook, FaInstagram, FaGithub } from 'react-icons/fa';
import  AvatarEditor  from 'react-avatar-editor';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Helmet} from "react-helmet";

const PublicCard = () => {
  const { url } = useParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [company, setCompany] = useState('');
  const [designation, setDesignation] = useState('');
  const [location, setLocation] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [users, setUsers] = useState([]);

  const [fb, setFb] = useState([]);
  
  const [ins, setIns] = useState([]);
  const [ln, setLn] = useState([]);
  const [gt, setGt] = useState([]);
  const [tt, setTt] = useState([]);


 

  useEffect(() => {
    const fetchMicroWebsites2 = async () => {
      try {
        const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/micrwebsites/card/${url}`);
        setUsers(response.data.microCards);
      } catch (error) {
        console.error('Error fetching micro websites:', error);
      }
    };

    fetchMicroWebsites2();
  }, [url]);

  useEffect(() => {
    setName(users.map(item => item.name));
    setEmail(users.map(item => item.email));
    setLocation(users.map(item => item.location));
    setCompany(users.map(item => item.company));
    setContact(users.map(item => item.contact));
    setDesignation(users.map(item => item.designation));
    setProfileImage(users.map(item => item.profilepic)); // assuming 'profileImage' is the key in your data for the profile image link
    setCoverPhoto(users.map(item => item.coverpic)); 

    setFb(users.map(item => item.facebook)); 
    setIns(users.map(item => item.instagram)); 
    setLn(users.map(item => item.linkedin)); 
    setGt(users.map(item => item.github)); 
    setTt(users.map(item => item.twiter)); 
   
    // assuming 'coverPhoto' is the key in your data for the cover photo link
  }, [users]);
  console.log("profile pic",profileImage);

  return (
    <div className="col-md-6">
       <Helmet>
        <title>Profile Card - ShareCrd</title>
        <meta name="description" content="Online digital profile card with ShareCrd.com"/>
    </Helmet>
    <div className="card mb-3">
   
      {coverPhoto && (
        <div
          className="card-img-top position-relative"
          style={{ maxHeight: '200px', overflow: 'hidden' }}
        >
          <img
            src={coverPhoto}
            alt="Cover"
            style={{ width: '100%', height: '100', objectFit: 'cover' }}
          />
          {profileImage && (
            <img
              src={profileImage}
              alt="Profile"
              className="rounded-circle position-absolute bottom-5 start-50 translate-middle-x"
              style={{ width: '200px', height: '200px', objectFit: 'cover' }}
            />
          )}
        </div>
      )}
      <div className="card-body " >
        <div style={{ height: '80x', objectFit: 'cover' }}>

        </div>
        
        <h2 className="card-title">
            <FaUser className="me-2" /> {name}
          </h2>
          {email && (
            <p className="card-text">
              <FaEnvelope className="me-2" /> {email}
            </p>
          )}
          {contact && (
            <p className="card-text">
              <FaPhone className="me-2" /> {contact}
            </p>
          )}
          {company && (
            <p className="card-text">
              <FaBuilding className="me-2" /> {company}
            </p>
          )}
          {designation && (
            <p className="card-text">
              <FaUser className="me-2" /> {designation}
            </p>
          )}
          {location && (
            <p className="card-text">
              <FaMapMarker className="me-2" /> {location}
            </p>
          )}
          
          <div className="mb-3">
            
            <label className="form-label">Social Media:</label>
            <div className="d-flex">
              <a href={ln} className="me-2">
                <FaLinkedin size={24} />
              </a>
              <a href={tt} className="me-2">
                <FaTwitter size={24} />
              </a>
              <a href={fb} className="me-2">
                <FaFacebook size={24} />
              </a>
              <a href={ins} className="me-2">
                <FaInstagram size={24} />
              </a>
              <a href={gt} className="me-2">
                <FaGithub size={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PublicCard };
