import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

// src/index.js
import React from 'react';

import { createRoot } from 'react-dom/client';

import { GoogleOAuthProvider } from '@react-oauth/google';
//im//port store from './redux/store';
//import { configureStore } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { rootReducer } from '../src/redux/reducers';
import App from './App';
import { Provider } from 'react-redux';
//const store = configureStore(rootReducer);
import {hydrate,render} from 'react-dom'

const rootElement = document.getElementById('root');
export const store = configureStore({
  reducer: {microWebsite: rootReducer},
})

const Appp = (
  <Provider store={store}> 
      <App /> 
    </Provider>

);

if(rootElement.hasChildNodes()){
  hydrate(Appp,rootElement);
}else{
  render(Appp,rootElement);
}





///////////////////old down
/*
const navRoot = createRoot(aaap); */
/*
navRoot.render(
  
  <Provider store={store}> 
      <App /> 
    </Provider>
   
 
);
*/
/////////////////old up


/////////////////down no use
/*
ReactDOM.render(
  <GoogleOAuthProvider clientId="835673584342-065llvk3fu6csqd80mqdc0c99g70j5tj.apps.googleusercontent.com">
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
</GoogleOAuthProvider>
);
*/

