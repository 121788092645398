

// src/App.js
import React,{useState} from 'react';
import { Badge, TabBar } from 'antd-mobile'
import { NavBar } from 'antd-mobile'
//import { initializeApp } from 'firebase/app';
//import { getAuth } from 'firebase/auth';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';

import { useNavigate } from 'react-router-dom';

import {
  
  Switch,
  useHistory,
  useLocation,
  
} from 'react-router-dom'
import Dashboard from './components/DashBoard';
import { GoogleLogin } from '@react-oauth/google';
import  { FC } from 'react'
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';
import Header from './components/Header';
import { EditorPage } from './pages/EditorPage';
import Home from './pages/Home';
import { PreviewPage } from './pages/PreviewPage';

import { auth } from './config';
import { provider } from './config';
import ShowLinks from './components/ShowLinks';

import{ EditorCard} from './components/EditCard';
import { PublicCard } from './components/PublicCard';
import { PublicCardPage } from './pages/PublicCardPage';
import { DashBoardPage } from './pages/DashBoardPage';
import { Login } from './components/login';
import { AuthContextProvider } from './context/AuthCOntext';
import { NewEditCard } from './components/NewEditCard';
import { MobileNavbar } from './components/MobileNavBar/MobileNavbar';
import {About} from './components/About'
import {PrivacyPolicy} from './components/PrivacyPolicy';
import {MobBar} from './components/MobBar';
import { NewProfile } from './components/NewProfile';
import { NewLinkCreate } from './components/NewLinkCreate';
import { AddLink } from './components/AddLink';
import { BioLinkPublic } from './components/BioLinkPublic';
import { BioLinkEdit } from './components/BioLinkEdit';
import { TermsAndConditions } from './components/TermsAndConditions';

////////////////////////////////////////
import {
  AppOutline,
  MessageOutline,
  UnorderedListOutline,
  UserOutline,
} from 'antd-mobile-icons'

////////////////////////////////////////
import { UserAuth } from './context/AuthCOntext';
function App() {
//const {user} = UserAuth();
   const [value, setValue] = React.useState('recents');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  ///const navigation = useNavigate();
  const navHome=()=>{
    window.location.href = 'http://sharecrd.com/';
    

  }
  const navCreate=()=>{
    window.location.href = 'http://sharecrd.com/editcard';
    
  }
  const navDash=()=>{
    window.location.href = 'http://sharecrd.com/dashboard';
    
  }
  const navAbout=()=>{
    window.location.href = 'http://sharecrd.com/about';
    
  }
  const linkBio=()=>{
    window.location.href = 'http://sharecrd.com/linkinbio';
    
  }
  

  const [activeKey, setActiveKey] = useState('todo')

  
  return (
    <Router>

       
      <div>
      
     <AuthContextProvider>
        <Header/>
       
        <main>
    
          <Routes>
          
          <Route path="/" element={<Home />} />
        <Route path="/editor" element={<EditorPage />} />
        <Route path="/preview" element={<PreviewPage />} />
        <Route path="/myLinks" element={<ShowLinks />} />
      
        <Route path="/login" element={<Login/>} />
        <Route path="/editcard" element={<EditorCard />} />
        <Route path="/card/:url" element={<PublicCard />} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/card/edit/:urlg" element={<NewEditCard/>} />
        <Route path="/about" element={<About/>} />
         <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
       { <Route path="/termsandconditions" element={<TermsAndConditions/>} /> }

         <Route path="/linkinbio" element={<NewLinkCreate/>} />
         <Route path="/addlinkinbio/:biolink" element={<AddLink/>} />
         <Route path="/links/:link" element={<BioLinkPublic/>} />
         <Route path="/linkedit/:link" element={<BioLinkEdit/>} />
            </Routes>
          
        </main>
      

     <Footer/>

     <BottomNavigation sx={{ position: 'fixed',
            bottom: 0,
            left: 0,
            width: 400,
            height:50
           
           
           
            }} value={value} onChange={handleChange}>
      <BottomNavigationAction
     onClick={navHome
    
     }
        label="Home"
        value="Home"
        
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
      onClick={navCreate}
        label="Create Card"
        value="Create Card"
        icon={<BorderColorIcon />}
      />
      <BottomNavigationAction
      onClick={navDash}
        label="Dashboard"
        value="Dashboard"
        icon={<DashboardIcon/>}
      />
        <BottomNavigationAction
      onClick={linkBio}
        label="LinkInBio"
        value="LinkInBio"
        icon={<DatasetLinkedIcon/>}
      />
      <BottomNavigationAction onClick={navAbout} label="About" value="About" icon={<InfoIcon />} />
    </BottomNavigation>


     </AuthContextProvider>
      </div>
    </Router>
    
  );
}

export default App;
