// src/pages/PreviewPage.js
import React, { useState, useEffect } from 'react';
import { FaUser, FaEnvelope, FaPhone, FaBuilding, FaMapMarker, FaLinkedin, FaTwitter, FaFacebook, FaInstagram, FaGithub } from 'react-icons/fa';
import AvatarEditor from 'react-avatar-editor';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { auth, storage } from '../config';
import { useDispatch } from 'react-redux';
import { createMicroCard } from '../redux/actions';
import { createMicroCardMod } from '../redux/actions';
import {Helmet} from "react-helmet";
import { UserAuth } from '../context/AuthCOntext';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes } from '@firebase/storage';
import { getDownloadURL } from '@firebase/storage';
const NewEditCard = () => {
  const { urlg } = useParams();
  const dispatch = useDispatch();
  const {user} = UserAuth();
  const [users, setUsers] = useState([]);
  const [editable, setEditable] = useState(false);
  const [editedData, setEditedData] = useState({
    name: '',
    email: '',
    contact: '',
    company: '',
    designation: '',
    location: '',
    profileImage: null,
    coverPhoto: null,
    fb: '',
    ins: '',
    ln: '',
    gt: '',
    tt: '',
  });
const navigate = useNavigate();
  useEffect(() => {
    const fetchMicroWebsites2 = async () => {
      try {
        const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/micrwebsites/card/${urlg}`);
        setUsers(response.data.microCards);
      } catch (error) {
        console.error('Error fetching micro websites:', error);
      }
    };

    fetchMicroWebsites2();
  }, [urlg]);

  useEffect(() => {
    if(user===null){
navigate('/login');
    }
    console.log('jnknk'+user);
    if (users.length > 0) {
      const userData = users[0];
      setEditedData({
        name: userData.name,
        email: userData.email,
        contact: userData.contact,
        company: userData.company,
        designation: userData.designation,
        location: userData.location,
        profileImage: userData.profilepic,
        coverPhoto: userData.coverpic,
        fb: userData.facebook,
        ins: userData.instagram,
        ln: userData.linkedin,
        gt: userData.github,
        tt: userData.twitter,
      });
    }
  }, [users]);

  const handleInputChange = (field, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

 /* const handleImageChange = (e, field) => {
    const file = e.target.files[0];
    setEditedData((prevData) => ({
      ...prevData,
      [field]: file,
    }));
  };
*/
const userId = user?.uid;
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
   // setProfileImage(file);
  
    // Upload the image to Firebase Storage
    const ProfileStorageRef = ref(storage,`profileImages/${userId}_${Date.now()}`)
   // const profileImageRef = storageRef.child(`profileImages/${userId}_${Date.now()}`);
  
   uploadBytes(ProfileStorageRef,file).then((snapshot)=>{
    console.log('profile done')
    getDownloadURL(snapshot.ref).then( url => {
      console.log(url);
     // setProfileImageURL(url);
      setEditedData({ ...editedData, profileImage: url })

    });
  //  console.log(profileImageURL);
   })
  
    // Get the download URL
    /*const downloadURL = await ProfileStorageRef.getDownloadURL();
    setProfileImageURL(downloadURL);*/
  };
  
  const handleCoverPhotoChange = async (e) => {
    const file = e.target.files[0];
  //  setCoverPhoto(file);
  
    // Upload the image to Firebase Storage
    const CoverStorageRef = ref(storage,`coverImages/${userId}_${Date.now()}`)
    // const profileImageRef = storageRef.child(`profileImages/${userId}_${Date.now()}`);
   
    uploadBytes(CoverStorageRef,file).then((snapshot)=>{
      
      console.log('cover done');
      getDownloadURL(snapshot.ref).then( url => {
       // console.log(url);
       // setCoverPhotoURL(url);
        setEditedData({ ...editedData, coverPhoto: url })
       // console.log(coverPhotoURL);
      });
     // console.log(coverPhotoURL);
      
 
    })
  
   
  
    // Get the download URL
   /* const downloadURL = await CoverStorageRef.getDownloadURL();
    setCoverPhotoURL(downloadURL);*/
  };

  const handleEditSubmit = () => {
    // Add logic to dispatch the edited details to your Redux action
    // You may want to perform validation before dispatching
    console.log('Edited Data:', editedData);
    console.log('user:'+user?.uid+"and "+editedData.userId)
    const userD = users[0];
    if(user?.uid === userD.userId){
      console.log('done')
      
    dispatch(createMicroCardMod(editedData.userId, urlg, editedData.name, editedData.company, editedData.designation, editedData.contact, editedData.email, editedData.profileImage, editedData.coverPhoto, editedData.location,editedData.ln,editedData.fb,editedData.ins,editedData.gt,editedData.tt));
    }else{
      navigate('/login')
    }
    setEditable(false); // Disable edit mode after submitting
    
        // Use profileImageURL and coverPhotoURL in your Redux action
       // dispatch(createMicroCardMod(editedData.userId, urlg, editedData.name, editedData.company, editedData.designation, editedData.contact, editedData.email, editedData.profileImage, editedData.coverPhoto, editedData.location,editedData.facebook,editedData.instagram,editedData.github,editedData.twitter));
     
  };

  return (
    <div className="col-md-6">
      <Helmet>
        <title>Edit Profile Card - ShareCrd</title>
        <meta name="description" content="Edit Online digital profile card with ShareCrd.com"/>
    </Helmet>
      <div className="card mb-3">
        {editedData.coverPhoto && (
          <div
            className="card-img-top position-relative"
            style={{ maxHeight: '200px', overflow: 'hidden' }}
          >
            <img
              src={editedData.coverPhoto}
              alt="Cover"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            {editedData.profileImage && (
              <img
                src={editedData.profileImage}
                alt="Profile"
                className="rounded-circle position-absolute bottom-5 start-50 translate-middle-x"
                style={{ width: '200px', height: '200px', objectFit: 'cover' }}
              />
            )}
          </div>
        )}
        <div className="card-body">
          {editable ? (
            <>
              <div className="mb-3">
                <label className="form-label">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.name}
                  onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email:</label>
                <input
                  type="email"
                  className="form-control"
                  value={editedData.email}
                  onChange={(e) => setEditedData({ ...editedData, email: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Contact:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.contact}
                  onChange={(e) => setEditedData({ ...editedData, contact: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Company:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.company}
                  onChange={(e) => setEditedData({ ...editedData, company: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Designation:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.designation}
                  onChange={(e) => setEditedData({ ...editedData, designation: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Location:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.location}
                  onChange={(e) => setEditedData({ ...editedData, location: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Social Media - LinkedIn:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.ln}
                  onChange={(e) => setEditedData({ ...editedData, ln: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Social Media - Twitter:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.tt}
                  onChange={(e) => setEditedData({ ...editedData, tt: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Social Media - Facebook:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.fb}
                  onChange={(e) => setEditedData({ ...editedData, fb: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Social Media - Instagram:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.ins}
                  onChange={(e) => setEditedData({ ...editedData, ins: e.target.value })}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Social Media - Github:</label>
                <input
                  type="text"
                  className="form-control"
                  value={editedData.gt}
                  onChange={(e) => setEditedData({ ...editedData, gt: e.target.value })}
                />
              </div>
              <div className="mb-3">
            <label className="form-label">Profile Photo:</label>
            <input
              type="file"
              className="form-control"
              onChange={handleImageChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Cover Photo:</label>
            <input
              type="file"
              className="form-control"
              onChange={handleCoverPhotoChange}
            />
          </div>
              <button className="btn btn-primary" onClick={handleEditSubmit}>
                Save Changes
              </button>
            </>
          ) : (
            <>
              <h2 className="card-title">
                <FaUser className="me-2" /> {editedData.name}
              </h2>
              {editedData.email && (
                <p className="card-text">
                  <FaEnvelope className="me-2" /> {editedData.email}
                </p>
              )}
              {editedData.contact && (
                <p className="card-text">
                  <FaPhone className="me-2" /> {editedData.contact}
                </p>
              )}
              {editedData.company && (
                <p className="card-text">
                  <FaBuilding className="me-2" /> {editedData.company}
                </p>
              )}
              {editedData.designation && (
                <p className="card-text">
                  <FaUser className="me-2" /> {editedData.designation}
                </p>
              )}
              {editedData.location && (
                <p className="card-text">
                  <FaMapMarker className="me-2" /> {editedData.location}
                </p>
              )}
              <div className="mb-3">
                <label className="form-label">Social Media:</label>
                <div className="d-flex">
                  <a href={editedData.ln} className="me-2">
                    <FaLinkedin size={24} />
                  </a>
                  <a href={editedData.tt} className="me-2">
                    <FaTwitter size={24} />
                  </a>
                  <a href={editedData.fb} className="me-2">
                    <FaFacebook size={24} />
                  </a>
                  <a href={editedData.ins} className="me-2">
                    <FaInstagram size={24} />
                  </a>
                  <a href={editedData.gt} className="me-2">
                    <FaGithub size={24} />
                  </a>
                </div>
              </div>
              <button className="btn btn-secondary" onClick={() => setEditable(true)}>
                Edit Details
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { NewEditCard };
