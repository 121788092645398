

// src/components/Header.js
import { React, useEffect } from 'react';
//////////////////////////
import { useState } from 'react';
import {auth,provider} from '../config'
import { signInWithPopup } from 'firebase/auth';
///////////////////////////////
import Navbar from './NavBar/NavBarSt';
import { Link } from 'react-router-dom';
import { MobileNavbar } from './MobileNavBar/MobileNavbar';


import {
  GoogleLogin,
  googleLogout,
  useGoogleLogin,
} from '@react-oauth/google';
import { Button } from 'bootstrap';




export const auth1 = auth;
export const provider1 = provider;


const Header = ({ isAuthenticated, onLogout }) => {
 
  const [value,setValue] = useState('');
  const handleClick=()=>{
    console.log('clicked 1');
    signInWithPopup(auth,provider).then((data)=>{
      console.log('clicked');
      setValue(data.user.email);
      localStorage.setItem('email',data.user.email);
      

    })
    const displayn= '';
    if(isAuthenticated){
       displayn = (<p>{auth.currentUser.displayName}</p>)
    }
   
  }

  
  useEffect(()=>{
    setValue(localStorage.getItem('email'));

  })

  
  

  return (
   <>
  <Navbar/>
 
  </>


  );
};
export default Header;
//signInWithPopup(GoogleAuthProvider)