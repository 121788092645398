import React, { useEffect, useState } from 'react';
 // Adjust the import path
 import '../css/LinkView.css'
import { Link } from 'react-router-dom';

import { auth } from '../config';
import axios from 'axios';
const ShowLinks = () => {
const [users,setUsers]=useState([]);
const userId= auth.currentUser?.uid;
useEffect(()=>{
   /* const fetchMicroWebsites2 = async () => {
        try {
          const response = await axios.get(`http://localhost:3000/api/microwebsites/${userId}`);
          setUsers(response.data.microWebsites);
        } catch (error) {
          console.error('Error fetching micro websites:', error);
        }
      };
      fetchMicroWebsites2();*/
      const fetchMicroWebsites2 = async () => {
        try {
          const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microwebsites/${userId}`);
          setUsers(response.data.microWebsites);
        } catch (error) {
          console.error('Error fetching micro websites:', error);
        }
      };
      fetchMicroWebsites2();

},[]);

   
const fetchMicroWebsites2 = async () => {
    try {
      const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microwebsites/${userId}`);
      setUsers(response.data.microWebsites);
    } catch (error) {
      console.error('Error fetching micro websites:', error);
    }
  };
console.log(users);
  if(users.length===0){
    fetchMicroWebsites2();
  }




  return (
    <div className="data-map-container">
      <h1>Your Data Map</h1>
      <div className="user-list">
        {users.map((item) => (
          <div key={item.id} className="user-item">
            <p>{item.content}</p>
            <a href={item.link} target="_blank" rel="noopener noreferrer">
              User ID: {item.userId}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowLinks;
