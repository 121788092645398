// Import the functions you need from the SDKs you need
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAfLx_rNSeclatBdnXUXEH7nbjAims7uSI",
  authDomain: "microweb-39a23.firebaseapp.com",
  databaseURL: "https://microweb-39a23-default-rtdb.firebaseio.com",
  projectId: "microweb-39a23",
  storageBucket: "microweb-39a23.appspot.com",
  messagingSenderId: "835673584342",
  appId: "1:835673584342:web:3605006077e8f6fdb08f77",
  measurementId: "G-THR9BJDP3J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);


export {app,auth, provider};

