// src/components/Preview.js
import { React, useEffect, useState } from 'react';
import axios from 'axios';

import { useSelector } from 'react-redux';
import { auth } from '../config';
const Preview = () => {
  // Retrieve the micro website content from the Redux store
  
  const [users,setUsers]= useState([]);
  const userId = auth.currentUser?.uid;

  useEffect(() => {
    const fetchMicroWebsites = async () => {
      try {
        const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microwebsites/${userId}`);
        setUsers(response.data.microWebsites);
      } catch (error) {
        console.error('Error fetching micro websites:', error);
      }
    };

    fetchMicroWebsites();
  }, []); // 
  const content = useSelector((state) => state.microWebsite.content);
 /* const fetchMicroWebsites = async () => {
    try {
      const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microwebsites/${userId}`);
      setUsers(response.data.microWebsites);
    } catch (error) {
      console.error('Error fetching micro websites:', error);
    }
  };*/

 // fetchMicroWebsites();
 const fetchMicroWebsites2 = async () => {
  try {
    const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microwebsites/${userId}`);
    setUsers(response.data.microWebsites);
  } catch (error) {
    console.error('Error fetching micro websites:', error);
  }
};

if(users.length===0){
  fetchMicroWebsites2();
}

  return (
    <div>
      <p>{}</p>
      <h2>Micro Website Preview</h2>
      <div>
      <ul>
        {users?.map((item, index) => (
          <li key={index} dangerouslySetInnerHTML={{ __html: item.content }} />
        ))}
      </ul>
      </div>
     
    </div>
  );
};

export { Preview };
