// Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { provider } from '../config';
import { Navigate, useNavigate } from 'react-router-dom';
//import { app } from '../config';
import { getAuth } from 'firebase/auth';
import { UserAuth } from '../context/AuthCOntext';
import { FaRegCopy } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MdOutlinePreview } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import {Helmet} from "react-helmet";
import { BsCardChecklist } from 'react-icons/bs';
import { Button } from 'antd-mobile';
const Dashboard = () => {
  const [microWebsites, setMicroWebsites] = useState([]);
  const firebaseConfig = {
    apiKey: "AIzaSyAfLx_rNSeclatBdnXUXEH7nbjAims7uSI",
    authDomain: "microweb-39a23.firebaseapp.com",
    databaseURL: "https://microweb-39a23-default-rtdb.firebaseio.com",
    projectId: "microweb-39a23",
    storageBucket: "microweb-39a23.appspot.com",
    messagingSenderId: "835673584342",
    appId: "1:835673584342:web:3605006077e8f6fdb08f77",
    measurementId: "G-THR9BJDP3J"
  };
  const navigate = useNavigate();
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  const {user,logOut} = UserAuth();


const auth =getAuth(app);
const uid = user?.uid;
const userId = user?.uid;
console.log(userId);

const [users,setUsers]= useState([]);
useEffect(() => {

  if(!user){
    navigate('/login');
  }

 
    const fetchMicroWebsites = async () => {
      try {
       
        
     
        const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microcards/${user?.uid}`);
        
        setUsers(response.data.microCards);
        console.log(response.data.microCards);
      } catch (error) {
        console.error('Error fetching micro websites:', error);
      }
    };

    fetchMicroWebsites();
  }, [user]); // 

  const[linkinbio,setLinkinbio]=useState([]);

  useEffect(() => {
    const fetchProfileLink = async () => {
      try {
        const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microbiolinkid/${userId}`);
        setLinkinbio(response.data.microLinks);
        console.log(response.data.microLinks);
      } catch (error) {
        console.error('Error fetching micro websites:', error);
      }
    };

    fetchProfileLink();
  }, [user]);
 /* const fetchMicroWebsites = async () => {
    try {
   
      const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microcards/${userId}`);
      
      setUsers(response.data.microCards);
      console.log(response.data.microCards);
    } catch (error) {
      console.error('Error fetching micro websites:', error);
    }
  };
///just typed
if(userId && users===null){


    fetchMicroWebsites();
}*/

  const handleEdit = (microWebsiteId) => {
  
    window.location.href = 'http://sharecrd.com/card/edit/'+microWebsiteId;
  };
  const handlePreview = (microWebsiteId) => {

    window.location.href = 'http://sharecrd.com/card/'+microWebsiteId;
   
  };
  const handleLinkPreview = (microWebsiteId) => {
    
    window.location.href = 'http://sharecrd.com/links/'+microWebsiteId;
   
  };
  const copyToClipboard = (microWebsiteId) => {
   
    navigator.clipboard.writeText("https://sharecrd.com/card/"+microWebsiteId);
    alert("Url Copied!")
   
  };


  const copyToClipboardL = (lurl) => {
    
    navigator.clipboard.writeText("https://sharecrd.com/links/"+lurl);
    alert("Url Copied!")
   
  };

  const AddLink = (lurl) => {
    
  
    window.location.href = 'https://sharecrd.com/addlinkinbio/'+lurl;
   
   
  };
  const openEdit = (lurl) => {
    
  
    window.location.href = 'https://sharecrd.com/linkedit/'+lurl;
   
   
  };

  const handleDelete = async (microWebsiteId) => {
    try {
      console.log(microWebsiteId);
      await axios.get(`https://expressserver-rm8n.onrender.com/api/microcards/delete/${microWebsiteId}`); // Update the endpoint
      // Refresh the micro websites list after deletion
     /* const response = await axios.get('http://localhost:3000/api/microcard'); // Update the endpoint
      setMicroWebsites(response.data.microWebsites);*/
      const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microcards/${user?.uid}`);
        
      setUsers(response.data.microCards);
     
    
    } catch (error) {
      console.error('Error deleting micro website:', error);
    }
  
  };

  const handleDeleteLink = async (id) => {
    try {
      console.log(id);
      await axios.get(`https://expressserver-rm8n.onrender.com/api/microbiolink/delete/${id}`); // Update the endpoint
      // Refresh the micro websites list after deletion
     /* const response = await axios.get('https://expressserver-rm8n.onrender.com/api/microcard'); // Update the endpoint
      setMicroWebsites(response.data.microWebsites);*/
      const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microbiolinkid/${userId}`);
      setLinkinbio(response.data.microLinks);
     
    
    } catch (error) {
      console.error('Error deleting micro website:', error);
    }
  
  };
  

  return (
    <div>
    <Helmet>
      <title>Dashboard - ShareCrd</title>
    </Helmet>
    <h2>My Profile Cards:<Button onClick={()=>{navigate('/editcard')}}>Create New</Button></h2>
    {users.map((item) => (
      <div key={item._id} className="card mb-3 shadow-lg rounded">
        <div className="card-body" >
          <h5
            className="card-title custom-card-title"
            style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}
          >
            <BsCardChecklist className="mr-2" /> {item.url}
          </h5>
          <button className="btn btn-primary mr-2" onClick={() => handleEdit(item.url)}>
            <FaEdit />‎ Edit
          </button>
          <button className="btn btn-danger mr-2" onClick={() => handleDelete(item.url)}>
            <MdDelete />‎ Delete
          </button>
          <button className="btn btn-success mr-2" onClick={() => handlePreview(item.url)}>
            <MdOutlinePreview />‎ Preview
          </button>
          <button
            className="btn btn-dark mr-2"
            onClick={()=>copyToClipboard(item.url)}
          >
            <FaRegCopy />‎ Copy Link
          </button>
        </div>
      </div>
    ))}
     <h2> My LinkInBio:<Button onClick={()=>{navigate('/linkinbio')}}>Create New</Button></h2>
     {linkinbio.map((item) => (
      <div key={item._id} className="card mb-3 shadow-lg rounded">
        <div className="card-body" >
          <h5
            className="card-title custom-card-title"
            style={{ fontWeight: 'bold', fontFamily: 'Arial, sans-serif' }}
          >
            <BsCardChecklist className="mr-2" /> {item.title}
          </h5>
          <button className="btn btn-primary mr-2" onClick={() => AddLink(item.link)}>
            <FaEdit />‎ Add Link
          </button>
          <button className="btn btn-danger mr-2" onClick={() => handleDeleteLink(item._id)}>
            <MdDelete />‎ Delete
          </button>
          <button className="btn btn-primary mr-2" onClick={() => openEdit(item.link)}>
            <FaEdit />‎ Edit
          </button>
         
          <button className="btn btn-success mr-2" onClick={() => handleLinkPreview(item.link)}>
            <MdOutlinePreview />‎ Preview
          </button>
          <button
            className="btn btn-dark mr-2"
            onClick={() => copyToClipboardL(item.link)}
          >
            <FaRegCopy />‎ Copy Link
          </button>
        </div>
      </div>
    ))}
  </div>
  );
};

export default Dashboard;
