// src/redux/actions.js
import axios from 'axios';

export const createMicroWebsite = (userId, content,url,title) => {
  return async (dispatch) => {
    try {
      await axios.post('https://expressserver-rm8n.onrender.com/api/microwebsites', {
        userId,
        content,
        url,
        title
      });
      dispatch({
        type: 'CREATE_MICRO_WEBSITE',
        payload: { content,url,title },
      });
    } catch (error) {
      console.error('Error creating micro website:', error);
    }
  };
};

export const createMicroCard = (userId, url,name,company,designation,contact,email,profilepic,coverpic,location,linkedin,facebook,instagram,github,twitter) => {
  return async (dispatch) => {
    try {
      await axios.post('https://expressserver-rm8n.onrender.com/api/microcard', {
        userId, url,name,company,designation,contact,email,profilepic,coverpic,location,linkedin,linkedin, facebook,instagram,github,twitter
      });
      dispatch({
        type: 'CREATE_MICRO_CARD',
        payload: { url,name,company,designation,contact,email,profilepic,coverpic,location,linkedin,facebook,instagram,github,twitter },
      });
    } catch (error) {
      console.error('Error creating micro card:', error);
    }
  };
};

///////////////////////////////////

/////////////////////
export const createMicroCardMod = (userId, url, name, company, designation, contact, email, profilepic, coverpic, location,linkedin, facebook, instagram, github, twitter) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`https://expressserver-rm8n.onrender.com/api/microcard/${url}`, {
        userId, name, company, designation, contact, email, profilepic, coverpic, location,linkedin, facebook, instagram, github, twitter
      });

      if (response.data.success) {
        dispatch({
          type: 'CREATE_MICRO_CARD',
          payload: response.data.data, // Assuming your response contains the updated data
        });
      } else {
        console.error('Error creating micro card:', response.data.error);
      }
    } catch (error) {
      console.error('Error creating micro card:', error);
    }
  };
};
/////////////////////////////////////////create newLink for link cards
export const createNewLinkProfile = (userId, title,link,image) => {
  return async (dispatch) => {
    try {
      await axios.post('https://expressserver-rm8n.onrender.com/api/newlinkprofile', {
        userId,
        title,
        link,
        image
      });
      dispatch({
        type: 'CREATE_LINK_PROFILE',
        payload: { userId,title,link,image },
      });
    } catch (error) {
      console.error('Error creating micro website:', error);
    }
  };
};

export const createAddBioLink = (userId, title,link,image,biolink) => {
  return async (dispatch) => {
    try {
      await axios.post('https://expressserver-rm8n.onrender.com/api/addbiolink', {
        userId,
        link,
        title,
        link,
        image,
        biolink
      });
      dispatch({
        type: 'CREATE_ADD_BIOLINK',
        payload: { userId,link,title,link,image,biolink },
      });
    } catch (error) {
      console.error('Error creating micro website:', error);
    }
  };
};

//////////////////////////
 export const fetchMicroWebsites = (userId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `https://expressserver-rm8n.onrender.com/api/microwebsites/${userId}`
      );

       const microWebsites = response.data.microWebsites;
       //console.log(microWebsites);
      dispatch({
        type: 'FETCH_MICRO_WEBSITES',
        payload: { microWebsites },
      });
      
      
    } catch (error) {
      console.error('Error fetching micro websites:', error);
    }
  };
};




