// src/pages/PreviewPage.js
import { React } from 'react';

import { Preview } from '../components/Preview';

const PreviewPage = () => {
  return (
    <div>
      <Preview/>
    </div>
  );
};
export { PreviewPage };
