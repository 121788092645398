// src/pages/Home.js
import {React} from 'react';
import { Link } from 'react-router-dom';
import '../css/style.css'
import '../css/style.css.map'
import { UserAuth } from '../context/AuthCOntext';

const Home = () => {
  const {user} = UserAuth();

  const userName = user?.displayName;
  return (
    <>
  <nav class=" navbar-collapse " style={{width:'100%'}}>
  <div class=" navbar-collapse" style={{color:'white'}}>
   
   
   
  <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto">
		<p>Hello,</p>
      {
      user && <p style={{
        fontSize:25
      }}>Welcome, {userName}</p>}

    
        
      </ul>
    </div>
  </div>
</nav>

<section class="section gradient-banner">
	<div class="shapes-container">
		<div class="shape" data-aos="fade-down-left" data-aos-duration="1500" data-aos-delay="100"></div>
		<div class="shape" data-aos="fade-down" data-aos-duration="1000" data-aos-delay="100"></div>
		<div class="shape" data-aos="fade-up-right" data-aos-duration="1000" data-aos-delay="200"></div>
		<div class="shape" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200"></div>
		<div class="shape" data-aos="fade-down-left" data-aos-duration="1000" data-aos-delay="100"></div>
		<div class="shape" data-aos="fade-down-left" data-aos-duration="1000" data-aos-delay="100"></div>
		<div class="shape" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="300"></div>
		<div class="shape" data-aos="fade-down-right" data-aos-duration="500" data-aos-delay="200"></div>
		<div class="shape" data-aos="fade-down-right" data-aos-duration="500" data-aos-delay="100"></div>
		<div class="shape" data-aos="zoom-out" data-aos-duration="2000" data-aos-delay="500"></div>
		<div class="shape" data-aos="fade-up-right" data-aos-duration="500" data-aos-delay="200"></div>
		<div class="shape" data-aos="fade-down-left" data-aos-duration="500" data-aos-delay="100"></div>
		<div class="shape" data-aos="fade-up" data-aos-duration="500" data-aos-delay="0"></div>
		<div class="shape" data-aos="fade-down" data-aos-duration="500" data-aos-delay="0"></div>
		<div class="shape" data-aos="fade-up-right" data-aos-duration="500" data-aos-delay="100"></div>
		<div class="shape" data-aos="fade-down-left" data-aos-duration="500" data-aos-delay="0"></div>
    <div style={{height:150}}>

    </div>
    <div class="container"  >
		<div class="row align-items-center">
			<div class="col-md-6 order-2 order-md-1 text-center text-md-left">
				<h1 class="text-white font-weight-bold mb-4">Showcase your profile with Our Business Card</h1>
				<p class="text-white mb-5">Fastest may to share your business contacts online
					with our ShareCrd.</p>
				<a href="/editcard" class="btn btn-main-md">Create Now</a>
			</div>
      
			<div class="col-md-6 text-center order-1 order-md-2">
				
			</div>
		</div>
	</div>

	</div>
  
	<div class="container" >
		<div class="row align-items-center">
			<div class="col-md-6 order-2 order-md-1 text-center text-md-left">
				<h1 class="text-white font-weight-bold mb-4">Your Ultimate Business Card Platform</h1>
				<p class="text-white mb-5">Our platform allows you to create stunning digital business cards that represent you and your business professionally. The process is quick, easy, and best of all – it's completely free!</p>
				<a href="FAQ.html" class="btn btn-main-md">Download Now</a>
			</div>
      
			<div class="col-md-6 text-center order-1 order-md-2">
				
			</div>
		</div>
	</div>
</section>
<section class="feature section pt-0">
	<div class="container">
		<div class="row">
			<div class="col-lg-6 ml-auto align-self-center">
				<div class="feature-content">
			
					<h2>Your Ultimate Business Card Platform <a
							href="https://sharecrd.com">ShareCrd</a></h2>
					
					<p>Our platform allows you to create stunning digital business cards that represent you and your business professionally. The process is quick, easy, and best of all – it's completely free!</p>
				</div>
			
				<div class="testimonial">
					<p>
						
					</p>
					<ul class="list-inline meta">
						<li class="list-inline-item">
							{/*<img src="images/testimonial/feature-testimonial-thumb.jpg" alt=""/>*/}
						</li>
						<li class="list-inline-item"></li>
					</ul>
				</div>
			</div>
			<div class="col-lg-6 mr-auto justify-content-center">
			
				<div class="image-content" data-aos="fade-left">
					{/*<img class="img-fluid" src="images/feature/feature-new-02.jpg" alt="ipad"/>*/}
				</div>
			</div>
		</div>
	</div>
</section>




</>

  );
};
export default Home;
