import React from 'react';
import '../css/publicbiolink.css'
import { useState } from 'react';
import { UserAuth } from '../context/AuthCOntext';
import  AvatarEditor  from 'react-avatar-editor';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Helmet} from "react-helmet";

import { useDispatch } from 'react-redux';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const BioLinkEdit=()=>{
const {user}=UserAuth();
    const {link} = useParams();
    const[profile,setProfile]=useState([]);
    const[data,setData]=useState([]);
    const[url,setUrl]= useState('');
const {navigate} = useNavigate();
    useEffect(()=>{
       
        if(!user){
            navigate('/login');
          }
        
            const fetchProfileLink = async () => {
                try {
                  const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microbiolink/${link}`)
                  .then(response => {
                    setProfile(response.data.microLinks);
                    console.log(response.data.microLinks);// Assuming the API returns an array of card data
                    setUrl(profile.map(item => item.link));
                    console.log('got'+profile.map(item => item.link));

                    // Execute another program or action after the completion of the first program
                    fetchLinks();
                  })
                  
                } catch (error) {
                  console.error('Error fetching micro websites:', error);
                }
              };
          
              fetchProfileLink();
              

        
    },[link]);

        const fetchLinks = async () => {
            try {
                console.log('fetched url:,'+ url);
              const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microaddlink/${link}`);
              setData(response.data.microAddLinks);
              console.log(response.data.microAddLinks);
            } catch (error) {
              console.error('Error fetching micro websites:', error);
            }
          };
      
         
 const link2 = link;

          const handleDelete = async (link3) => {
            try {
              console.log(link3);
              await axios.get(`https://expressserver-rm8n.onrender.com/api/microaddlink/delete/${link3}`); // Update the endpoint
              // Refresh the micro websites list after deletion
             /* const response = await axios.get('http://localhost:3000/api/microcard'); // Update the endpoint
              setMicroWebsites(response.data.microWebsites);*/
              const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microaddlink/${link2}`);
              setData(response.data.microAddLinks);
             
            
            } catch (error) {
              console.error('Error deleting Link:', error);
            }
          
          };

    const Card = ({ title, image, link,id }) => (
        <div className='card-body' style={{ display: "flex", alignItems: "center" }}>
        <img src={image} alt={title} className="card-image" />
        <div style={{ marginLeft: "10px" }}>
            <h3 className="card-title">{title}</h3>
            <button className="card-button" onClick={() => handleDelete(id)}>Delete Link</button>
            
        </div>
    </div>
    
      );

   




    return(<div style={{alignItems:"center", justifyItems:"center"}}>
        <div className="card mb-3 shadow-lg rounded" style={{width:'100%'}}>
    <div className="profile">
    <img
      src={profile.map(item => item.image)} // Replace with your profile image URL
      alt="Profile"
      className="profile-image"
    />
    <h2 className="username">{profile.map(item => item.title)}</h2>
    </div>
  </div>


  <div className="card mb-3 shadow-lg rounded">
      {data.map((card, index) => (
        <div className="card mb-3 shadow-lg rounded">
        <Card key={index} title={card.title} image={card.image} link={card.link} id={card._id} />
        </div>
      ))}
    </div>
    </div>);


}
export {BioLinkEdit}
