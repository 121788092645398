import React from 'react';
import { useState } from 'react';

import  AvatarEditor  from 'react-avatar-editor';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {Helmet} from "react-helmet";
import { UserAuth } from '../context/AuthCOntext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import { ref, uploadBytes } from '@firebase/storage';
import { getDownloadURL } from '@firebase/storage';
import { auth, storage } from '../config'; 
import { createAddBioLink } from '../redux/actions';

const AddLink=()=>{
    const {biolink} = useParams();
    console.log(biolink);
const navigate = useNavigate();
   // const { link } = useParams();
   
    const [userId,setUserId] = useState('');
     
    const dispatch = useDispatch();

    const[profileTitle,setProfileTitle] = useState("");
    const[profileImage,setProfileImage]= useState("");

    const [url,setUrl] = useState("");
    const [title,setTitle] = useState("");
    const[image,setImage] = useState("");

    const [profile,setProfile]=useState([]);
    const {user}=UserAuth();
    useEffect(()=>{
      
      if(!user){
          navigate('/login');
        }
        
        setUserId(user?.uid);

    
    })

   useEffect(() => {
        const fetchProfileLink = async () => {
          try {
            const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microbiolink/${biolink}`);
            setProfile(response.data.microLinks);
            console.log(response.data.microLinks);
          } catch (error) {
            console.error('Error fetching micro websites:', error);
          }
        };
    
        fetchProfileLink();
      }, [biolink]);

      useEffect(() => {
        setProfileTitle(profile.map(item => item.title));
        setProfileImage(profile.map(item => item.image));
       
       
       
        // assuming 'coverPhoto' is the key in your data for the cover photo link
      }, [profile]);


      const handleImageChange = async (e) => {
        const file = e.target.files[0];
       
      
        // Upload the image to Firebase Storage
        const ProfileStorageRef = ref(storage,`linkPageProfile/${userId}_${Date.now()}`)
       // const profileImageRef = storageRef.child(`profileImages/${userId}_${Date.now()}`);
      
       uploadBytes(ProfileStorageRef,file).then((snapshot)=>{
        console.log('profile done')
        getDownloadURL(snapshot.ref).then( url => {
          console.log(url);
          setImage(url);
    
        });
        
       })
      
        
      };
      
const addCard=()=>{
    if(!user){

        alert("login");
    }else{
        dispatch(createAddBioLink(userId, title,url,image,biolink));
        alert("done");

    }
}




 


  return (
    <div className="container text-center mt-5">
      <div className="card bg-light p-4">
        <div>
          <img src={profileImage} alt="Profile" className="img-fluid" style={{ height: 200, width: 200, borderRadius: '50%' }} />
          <h2>{profileTitle}</h2>
        </div>
      </div>

      <form className="card mt-4 p-4">
        <label>Title:</label>
        <input
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="form-control mb-3"
        />

        <div className="mb-3">
          <label className="form-label">Profile Photo:</label>
          <input
            type="file"
            className="form-control"
            onChange={handleImageChange}
          />
        </div>

        <label>URL:</label>
        <input
          type="text"
          name="link"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="form-control mb-3"
        />

        <button type="button" className="btn btn-primary" onClick={addCard}>
          Add Card
        </button>
      </form>
    </div>
  );
};


export {AddLink};
