// src/components/Editor.js
import {
  React,
  useEffect,
  useState,
} from 'react';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  createMicroWebsite,
  fetchMicroWebsites
} from '../redux/actions';
//import { contentdata } from '../redux/actions';
//import { GoogleAuthProvider } from "firebase/auth";
import { auth,provider } from '../config';
import axios from 'axios';

//import { microWebsites } from '../redux/actions';

const Editor = () => {

  const [url,setUrl] = useState('');
  const [title,setTitle] = useState('');
  const [content, setContent] = useState('');


  const dispatch = useDispatch();
  const userId = auth.currentUser?.uid;
  const microWebsites = useSelector((state) => state.microWebsite.content);

 /* const response =  axios.get(
    `http://localhost:3000/api/microwebsites/${userId}`
  );*/

   //const microWebsitesdata = response.data;
   
const [users,setUsers] = useState([])
  useEffect(() => {
    if (userId) {
      dispatch(fetchMicroWebsites(userId));
      
    }
    const fetchMicroWebsites2 = async () => {
      try {
        const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microwebsites/${userId}`);
        setUsers(response.data.microWebsites);
      } catch (error) {
        console.error('Error fetching micro websites:', error);
      }
    };
    fetchMicroWebsites2();
    //setMicroWebsitesv(microWebsites);
   




  }, [dispatch, userId]);

  const fetchMicroWebsites2 = async () => {
    try {
      const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/microwebsites/${userId}`);
      setUsers(response.data.microWebsites);
    } catch (error) {
      console.error('Error fetching micro websites:', error);
    }
  };
console.log(users);
  if(users.length===0 && auth.currentUser?.uid){
    fetchMicroWebsites2();
  }

  
  const handleSave = () => {
    dispatch(createMicroWebsite(userId, content,url,title));
  };



    
  

 
 // console.log(ddd);


  

  return (
    <div>
      <h2>Micro Website Editor</h2>
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Enter your content here"
      />
      <textarea
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter your url"
      />
      <textarea
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Enter your title here"
      />
      <button onClick={handleSave}>Save</button>
      
      <h3>Your Micro Websites</h3>
      <ul>
        {users?.map((item) => (
          <li key={item._id}>
            <strong>User ID:</strong> {item.userId} <br />
            <strong>Content:</strong> {item.content}
          </li>
        ))}
      </ul>
      
     






      
      </div>
  );
     
       
     
  
};

export { Editor };
