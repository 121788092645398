// Login.js
import React, { useState } from 'react';
import { auth } from './config';// Path to your firebase.js file
import { GoogleAuthProvider, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthCOntext';
import {GoogleButton} from 'react-google-button';
import {Helmet} from "react-helmet";
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const{user} = UserAuth();

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Redirect the user after successful login
      navigate('/dashboard'); // Adjust the path accordingly
    } catch (error) {
      console.error('Login Error:', error.message);
    }
  };

  const handleSignUp = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      // Redirect the user after successful sign-up
      navigate('/dashboard'); // Adjust the path accordingly
    } catch (error) {
      console.error('Sign-up Error:', error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      // Redirect the user after successful Google sign-in
      navigate('/dashboard'); // Adjust the path accordingly
    } catch (error) {
      console.error('Google Sign-in Error:', error.message);
    }
  };

  return (
    <div className="container mt-5">
      <Helmet>
        <title>LogIn - ShareCrd</title>
        
    </Helmet>
      <div className="card">
        <div className="card-body">
          <h2 className="card-title">Login/Sign-up</h2>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <div style={{height:20}}>

          </div>
          <button className="btn btn-primary" onClick={handleLogin}>Login</button>
          
          <button className="btn btn-success ml-2" onClick={handleSignUp}>Sign-up</button>
          <div style={{height:12}}></div>
          <GoogleButton onClick={handleGoogleSignIn}/>
        </div>
        <div style={{height:140}}>

        </div >
      </div>
      <div style={{height:60}}>

</div >
    </div>

  );
};

export{  Login};
