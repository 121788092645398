import React, { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { auth, storage } from '../config'; // Assuming storage is exported from your Firebase config
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram, FaGithub } from 'react-icons/fa';
import { createMicroCard } from '../redux/actions';
import { ref, uploadBytes } from '@firebase/storage';
import { getDownloadURL } from '@firebase/storage';
import { UserAuth } from '../context/AuthCOntext';
import {Helmet} from "react-helmet";
import Lottie from 'react-lottie';
import animationData from '../Animation/done.json';
import { useNavigate } from 'react-router-dom';

import QRCode from 'qrcode.react';
//import { Button, InputGroup, FormControl, Alert } from 'https://cdn.jsdelivr.net/npm/react-bootstrap@2.10.0/dist/react-bootstrap.min.js';

const EditorCard = () => {
  const dispatch = useDispatch();
  const {user} = UserAuth();
  const userId = user?.uid;
const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [company, setCompany] = useState('');
  const [designation, setDesignation] = useState('');
  const [url, setUrl] = useState('');
  const [location, setLocation] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [profileImageURL, setProfileImageURL] = useState(''); // To store the download URL
  const [coverPhotoURL, setCoverPhotoURL] = useState(''); // To store the download URL
  const [exists, setExists] = useState(false);

  const [linkedin, setLinkedin] = useState('');
  const [twitter, setTwitter] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [github, setGithub] = useState('');

  const [canvasReady, setCanvasReady] = useState(false);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  
    // Upload the image to Firebase Storage
    const ProfileStorageRef = ref(storage,`profileImages/${userId}_${Date.now()}`)
   // const profileImageRef = storageRef.child(`profileImages/${userId}_${Date.now()}`);
  
   uploadBytes(ProfileStorageRef,file).then((snapshot)=>{
    console.log('profile done')
    getDownloadURL(snapshot.ref).then( url => {
      console.log(url);
      setProfileImageURL(url);

    });
    console.log(profileImageURL);
   })
  
    // Get the download URL
    /*const downloadURL = await ProfileStorageRef.getDownloadURL();
    setProfileImageURL(downloadURL);*/
  };
  
  const handleCoverPhotoChange = async (e) => {
    const file = e.target.files[0];
    setCoverPhoto(file);
  
    // Upload the image to Firebase Storage
    const CoverStorageRef = ref(storage,`coverImages/${userId}_${Date.now()}`)
    // const profileImageRef = storageRef.child(`profileImages/${userId}_${Date.now()}`);
   
    uploadBytes(CoverStorageRef,file).then((snapshot)=>{
      
      console.log('cover done');
      getDownloadURL(snapshot.ref).then( url => {
       // console.log(url);
        setCoverPhotoURL(url);
        console.log(coverPhotoURL);
      });
      console.log(coverPhotoURL);
      
 
    })
  
   
  
    // Get the download URL
   /* const downloadURL = await CoverStorageRef.getDownloadURL();
    setCoverPhotoURL(downloadURL);*/
  };




  const checkUrl = async () => {
    const url1 = url.trim();
    const fetchMicroWebsites2 = async () => {
      try {
        const response = await axios.get(`https://expressserver-rm8n.onrender.com/api/micrwebsites/card/${url1}`);
        setExists(response.data.exist);
        console.log(response.data.exist);
      } catch (error) {
        console.error('Error fetching micro websites:', error);
      }
    };

    fetchMicroWebsites2();
  };

  const handleGenerateCard = () => {
    checkUrl();

    if(!user){
      window.location.href = 'http://sharecrd.com/login';
    }


  
    if (!exists) {
      // Use profileImageURL and coverPhotoURL in your Redux action
      dispatch(createMicroCard(userId, url, name, company, designation, contact, email, profileImageURL, coverPhotoURL, location,linkedin ,facebook,instagram,github,twitter));
    openPopup();
    } else {
      alert("URL already exists!");
    }
  };

  ///////////qr
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
 
    
    const [copied, setCopied] = useState(false);
  
    const handleGenerateQRCode = () => {
      setCopied(false);
      // Additional logic for generating QR code based on the entered URL
    };
  
    const handleCopyToClipboard = () => {
      navigator.clipboard.writeText("https://sharecrd.com/card"+url);
      setCopied(true);
    };
    const [downloadUrl, setDownloadUrl] = useState(null);
    const handleDownloadQRCode = () => {
      const canvas = document.getElementById('qr-code-canvas');
  
      if (canvas) {
        const dataUrl = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = 'qrcode.png';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };


    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
      setIsOpen(true);
    };
  
    const closePopup = () => {
      setIsOpen(false);
    };
  
    const popupStyles = {
      display: isOpen ? 'block' : 'none',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
      zIndex: '1000',
    };
  
    const overlayStyles = {
      display: isOpen ? 'block' : 'none',
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '999',
    };
  
  return(
    <div className="container mt-5">
      <Helmet>
        <title>Create Digital Profile Card for Free - ShareCrd</title>
        
    </Helmet>
    <div>
<div style={overlayStyles} onClick={closePopup}></div>

<div style={popupStyles}>
  <span style={{ cursor: 'pointer' }} onClick={closePopup}>X</span>
  <p>YOur Digital Profile is ready and live!</p>
  <Lottie 
	    options={defaultOptions}
        height={300}
        width={300}
      />
  {url && (
            <div>
              <QRCode
                value={'https://sharecrd/card/'+url}
                size={200}
                id="qr-code-canvas"
                onLoad={() => setCanvasReady(true)}
              />
             
            </div>
          )}
         
               
               <button className="btn btn-secondary" onClick={handleCopyToClipboard}>
                 {copied ? 'Copied!' : 'Copy to Clipboard'}
               </button>
               <button className="btn btn-success" onClick={handleDownloadQRCode}>
                 Download QR Code
               </button>

         
  <button className="btn btn-primary"  onClick={()=>{navigate('/card/'+url)}}>Open Profile</button>
</div>
</div>
      <div className="row">
        {/* Left Side: Editing Elements */}
        <div className="col-md-6">
        <div className="mb-3">
            <label className="form-label">Public Url:</label>
            <input
              type="text"
              className="form-control"
              value={url}
              onChange={(e) => setUrl(e.target.value.trim())}
              
            />
          </div>
          <div>
     
      <button onClick={checkUrl}>Check URL</button>
      {exists && <p style={{ color: 'red' }}>Url Not Availabe</p>}
{!exists && <p style={{ color: 'green' }}>Available</p>}
    </div>
          <div className="mb-3">
            <label className="form-label">Name:</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email:</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Contact:</label>
            <input
              type="text"
              className="form-control"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">
Organization/Company:</label>
            <input
              type="text"
              className="form-control"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Designation:</label>
            <input
              type="text"
              className="form-control"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Location:</label>
            <input
              type="text"
              className="form-control"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Profile Photo:</label>
            <input
              type="file"
              className="form-control"
              onChange={handleImageChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Cover Photo:</label>
            <input
              type="file"
              className="form-control"
              onChange={handleCoverPhotoChange}
            />
          </div>
          <div className="d-flex">
          {/* LinkedIn */}
          <input
            type="text"
            className="form-control me-2"
            placeholder="LinkedIn"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
          />
          <FaLinkedin size={24} />

          {/* Twitter */}
          <input
            type="text"
            className="form-control me-2"
            placeholder="Twitter"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
          />
          <FaTwitter size={24} />

          {/* Facebook */}
          <input
            type="text"
            className="form-control me-2"
            placeholder="Facebook"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />
          <FaFacebook size={24} />

          {/* Instagram */}
          <input
            type="text"
            className="form-control me-2"
            placeholder="Instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
          <FaInstagram size={24} />

          {/* Github */}
          <input
            type="text"
            className="form-control me-2"
            placeholder="Github"
            value={github}
            onChange={(e) => setGithub(e.target.value)}
          />
          <FaGithub size={24} />
        </div>
        <div className="card mb-3" >
      
      </div>

          
          <button className="btn btn-primary" onClick={handleGenerateCard}>
            Generate Business Card
          </button>
        </div>

        {/* Right Side: Business Card Preview */}
        <div className="col-md-6">
          <h2>Business Card Preview:</h2>
          <div className="card" style={{ width: '18rem' }}>
            {coverPhoto && (
              <img
                src={URL.createObjectURL(coverPhoto)}
                alt="Cover"
                className="card-img-top rounded-top"
              />
            )}
             {profileImage && (
            <img
              src={URL.createObjectURL(profileImage)}
              alt="Profile"
              className="rounded-circle position-absolute bottom-5 start-50 translate-middle-x"
              style={{ width: '200px', height: '200px', objectFit: 'cover' }}
            />
          )}
            <div className="card-body">
              <div style={{height:70}}>

              </div>
           
              <p className="card-text">Name: {name}</p>
              <p className="card-text">Email: {email}</p>
             {contact && <p className="card-text">Contact: {contact}</p>}
              <p className="card-text">Company: {company}</p>
              <p className="card-text">Designation: {designation}</p>
              <p className="card-text">Location: {location}</p>
              <div className="mb-3">
              
                <div className="d-flex">
                  {/* Add more social media icons and links as needed */}
                  <div className="mb-3">
        <label className="form-label">Social Media:</label>
        <div className="d-flex">
          {/* LinkedIn */}
   
          <FaLinkedin size={24} />

          {/* Twitter */}
   
          <FaTwitter size={24} />

          {/* Facebook */}
     
          <FaFacebook size={24} />

          {/* Instagram */}
      
          <FaInstagram size={24} />

          {/* Github */}
         
          <FaGithub size={24} />
          
        </div>
        
      </div>
      
                </div>
              </div> <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <h2>QR Code </h2>
         
          {url && (
            <div>
              <QRCode
                value={'https://sharecrd/card/'+url}
                size={200}
                id="qr-code-canvas"
                onLoad={() => setCanvasReady(true)}
              />
              <div className="input-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Generated URL"
                  value={'https://sharecrd.com/card/'+url}
                  readOnly
                />
                <button className="btn btn-secondary" onClick={handleCopyToClipboard}>
                  {copied ? 'Copied!' : 'Copy to Clipboard'}
                </button>
                <button className="btn btn-success" onClick={handleDownloadQRCode}>
                  Download QR Code
                </button>

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
              {/* Additional fields or styling for the business card */}
            </div>
          </div>
        </div>
      </div>
    </div>

  
  
  );
};

export { EditorCard};

