// components/Navbar/index.js

import React, { useEffect, useState } from "react";
import {
	Nav,
	NavLink,
	Bars,
	NavMenu,
	NavBtn,
	NavBtnLink,
} from "./navbarElements";
import './navCss.css'
import { Button } from "bootstrap";
import { onAuthStateChanged, signInWithPopup } from 'firebase/auth';
import {provider} from '../config'
import { getAuth } from "firebase/auth";
import { UserAuth } from "../../context/AuthCOntext";

import { initializeApp } from "firebase/app";
import { FaBorderAll } from "react-icons/fa";
import { Await } from "react-router-dom";
import {GoogleButton} from 'react-google-button';

import { IoPerson } from "react-icons/io5";



const Navbar = () => {

  const {user,logOut} = UserAuth();
  const [authp ,setAuthp] = useState("");

  
 

  const firebaseConfig = {
    apiKey: "AIzaSyAfLx_rNSeclatBdnXUXEH7nbjAims7uSI",
    authDomain: "microweb-39a23.firebaseapp.com",
    databaseURL: "https://microweb-39a23-default-rtdb.firebaseio.com",
    projectId: "microweb-39a23",
    storageBucket: "microweb-39a23.appspot.com",
    messagingSenderId: "835673584342",
    appId: "1:835673584342:web:3605006077e8f6fdb08f77",
    measurementId: "G-THR9BJDP3J"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

 

  useEffect(() => {
    const fetchMicroWebsites = async () => {
      try {
        setAuthp(auth)
        
      } catch (error) {
        console.error('Error fetching micro websites:', error);
      }
    };
    fetchMicroWebsites();

   
  }, []); // 

 const handleClick=()=>{
 
    signInWithPopup(auth,provider).then((data)=>{
      
      
      localStorage.setItem('name',data.user.displayName);
      

    })

    
   
   
  }
  const handleLogOut=async()=>{
    try{
      await logOut();
    }catch(error){
      console.log('error');
    }
    

    
    
   
   
  }
  const displayn= localStorage.getItem('name');
    

    const myButton = new Button();

	return (
		<>
			<nav>
     
      <label for="check" class="checkbtn">
        <i class="fas fa-bars"></i>
      </label>
      <label class="logo" href="/">ShareCrd</label>
      <ul>
        <li><a class="active" href="/">Home</a></li>
        <li><a href="/editcard">Profile Card</a></li>
        <li><a href="/linkinbio">Link in Bio</a></li>
        <li><a href="/dashboard">DashBoard</a></li>
        <li><a href="/about">About</a></li>
       
        <li>
		{user? (
         <a >{user?.displayName}</a>,
         <li><a style={{color:"white"}}  onClick={handleLogOut}>LogOut</a></li>
        ) : (
          <GoogleButton onClick={handleClick}></GoogleButton>
          
        )}
        </li>
        <li>

        {user &&
        <a style={{color:'white'}} > <IoPerson/>{' '+user?.displayName}</a>
}
</li>
        
       
		
      </ul>
      
    </nav>
		</>
	);
};

export default Navbar;
