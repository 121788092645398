// src/redux/reducers.js
const initialState = {
    microWebsite: {
      content: '',
      
    },
  };


const rootReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CREATE_MICRO_WEBSITE':
        return {
          ...state,
          microWebsite: {
            content: action.payload.content ,
          },
        };
        case 'CREATE_MICRO_CARD':
        return {
          ...state,
          microWebsite: {
            content: action.payload.content ,
          },
        };
      case 'FETCH_MICRO_WEBSITES':
        return {
          ...state,
          microWebsites:{ content:action.payload.microWebsites ,
          },
        };
      default:
        return state;
    }
  };
export { rootReducer };
